enum ButtonText {
  SignUp = 'Sign Up',
  ContinueReading = 'Continue Reading',
  Upgrade = 'Upgrade',
  ChooseAPlan = 'Choose a Plan',
  JoinToWatch = 'Join to Watch / Upgrade Now',
}

type Link = {
  buttonText: ButtonText;
  href: string;
};

export const ArticlePaywall: Link = {
  buttonText: ButtonText.ContinueReading,
  href: '/subscribe/reader',
  // href: 'https://public.dailywire.com/links/sign-up/defaults/article/index.html',
};

export const ShopMemberOnlyProductPaywall: Link = {
  buttonText: ButtonText.Upgrade,
  href: 'https://public.dailywire.com/links/sign-up/shop-membership-upgrade/index.html',
};

export const FreeAccountManagement: Link = {
  buttonText: ButtonText.ChooseAPlan,
  href: 'https://public.dailywire.com/links/sign-up/upgrade-your-free-account/index.html',
};

export const AllAccessLiveEpisodePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/all-access-live-episode/index.html',
};

export const TheGreatestLiePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/content/the-greatest-lie/index.html',
};

export const WhatIsAWomanPaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/content/wiaw/index.html',
};

export const TerrorOnThePrairiePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/content/totp/index.html',
};

export const DefaultVideoPaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/defaults/video/index.html',
};

export const DefaultEpisodePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/defaults/episode/index.html',
};

export const DefaultClipPaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/defaults/clip/index.html',
};

export const TheDailyWireChannelEpisodePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/channels/the-daily-wire/index.html',
};

export const PragerUChannelEpisodePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/channels/prageru/index.html',
};

export const DragonsMonstersMenEpisodePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/content/dragons-monsters-men/index.html',
};

export const JordanPetersonChannelEpisodePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/channels/jordan-peterson/index.html',
};

export const BenShapiroSBookClubEpisodePaywall: Link = {
  buttonText: ButtonText.JoinToWatch,
  href: 'https://public.dailywire.com/links/sign-up/content/ben-shapiros-book-club/index.html',
};
